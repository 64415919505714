import React, {Component} from 'react';
import './Policy.scss';
import {NavLink} from "react-router-dom";

class Policy extends Component {
    render() {
        return (
            <article className="PrivacyPage">


                <h3>プライバシーポリシー</h3>

                <div>
                    <p>
                        リズムノック株式会社（以下「弊社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。
                    </p>

                    <h4>個人情報の定義</h4>

                    <p>本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義される個人情報を意味するものとします。</p>

                    <section>
                        <h4>個人情報の利用目的</h4>

                        <p>弊社は、個人情報を以下の目的で利用いたします。</p>

                        <ul>
                            <li>弊社の提供する技術支援、プロダクト支援若しくは組織作りのアドバイザリーサービス、又はIT事業若しくはITサービスの開発支援その他のサービス（以下「弊社サービス」といいます。）の提供のため</li>
                            <li>弊社サービスに関するご案内、お問い合せ等への対応のため</li>
                            <li>弊社のサービス等のご案内のため</li>
                            <li>弊社のサービスの改善、新サービスの開発等に役立てるため</li>
                            <li>弊雇用管理、業務委託先の管理及び社内手続のため（役職員及び業務委託先の個人情報について）、人材採用活動における選考及び連絡のため（応募者の個人情報について）</li>
                            <li>株主管理、会社法その他法令上の手続対応のため（株主、新株予約権者等の個人情報について）</li>
                            <li>その他、上記利用目的に付随する目的のため</li>
                        </ul>
                    </section>


                    <section>
                        <h4>個人情報利用目的の変更</h4>

                        <p>
                            弊社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。
                        </p>
                    </section>


                    <section>
                        <h4>個人情報利用の制限</h4>
                        <p>
                            弊社は、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
                        </p>

                        <ul>
                            <li>法令に基づく場合</li>
                            <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                            <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                            <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                        </ul>
                    </section>


                    <section>
                        <h4>個人情報の安全管理</h4>

                        <p>
                            弊社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、弊社の従業員に対し、必要かつ適切な監督を行います。また、弊社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
                        </p>
                    </section>

                    <section>
                        <h4>第三者提供</h4>
                        <p>
                            弊社は、第4項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
                        </p>
                        <ul>
                            <li>利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合</li>
                            <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                            <li>個人情報保護法の定めに基づき共同利用する場合</li>
                        </ul>
                    </section>


                    <section>
                        <h4>個人情報の開示</h4>
                        <p>
                            弊社は、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、弊社が開示の義務を負わない場合は、この限りではありません。
                        </p>
                    </section>

                    <section>
                        <h4>個人情報の訂正等</h4>
                        <p>
                            弊社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、弊社が訂正等の義務を負わない場合は、この限りではありません。
                        </p>
                    </section>

                    <section>
                        <h4>個人情報の利用停止等</h4>
                        <p>
                            弊社は、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、又は個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、弊社が利用停止等又は提供停止の義務を負わない場合は、この限りではありません。
                        </p>
                    </section>

                    <section>
                        <h4>COOKIE（クッキー）その他の技術の利用</h4>
                        <p>
                            弊社のサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、弊社による弊社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、弊社のサービスの一部の機能をご利用いただけなくなる場合があります。
                        </p>
                    </section>

                    <section>
                        <h4>お問い合わせ</h4>
                        <p>
                            開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、<NavLink to="/contact">お問合せフォーム</NavLink>よりお願い致します。
                        </p>
                    </section>

                    <section>
                        <h4>継続的改善</h4>
                        <p>弊社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。</p>
                    </section>

                </div>

            </article>
        );
    }
}

export default Policy;
