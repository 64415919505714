import './Footer.scss'
import * as React from "react";

interface FooterProps {
    year: string;
}

class Footer extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {year: new Date().getFullYear().toString()};
    }


    render() {
        return (
            <footer className="Footer">
                <article className="Info">
                    <section className="Posts">
                        <h4>最近の投稿</h4>
                        <section>
                            準備中
                        </section>
                    </section>
                    <section id="about" className="About">
                        <h4>RhythmKnockについて</h4>
                        <section>
                            <table>
                                <tbody>
                                <tr>
                                    <td className="Label">社名</td>
                                    <td>リズムノック株式会社（RHYTHM KNOCK CORPORATION）</td>
                                </tr>
                                <tr>
                                    <td className="Label">創立</td>
                                    <td>2019年（平成31年）4月1日</td>
                                </tr>
                                <tr>
                                    <td className="Label">事業年度</td>
                                    <td>毎年4月1日から翌年3月31日まで</td>
                                </tr>
                                <tr>
                                    <td className="Label">資本金</td>
                                    <td>300万円（2019年4月末現在）</td>
                                </tr>
                                <tr>
                                    <td className="Label">役員</td>
                                    <td>代表取締役社長 清水 大輔</td>
                                </tr>
                                <tr>
                                    <td className="Label">所在地</td>
                                    <td>
                                        101-0046<br/>
                                        東京都千代田区神田多町2-1 神田東山ビル7階
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </section>
                    </section>
                </article>

                <div className="License">
                    <div className="CopyWrite">© {this.state.year} RHYTHM KNOCK CO., LTD. ALL RIGHT RESERVED</div>
                </div>
            </footer>
        );
    }
}

export default Footer;
