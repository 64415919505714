import React, {Component} from 'react';
import Header from "../core/header/Header";
import Footer from "../core/footer/Footer";
import {Route, Switch} from "react-router-dom";
import Top from "./top/Top";
import Contact from "./contact/Contact";
import Policy from "./policy/Policy";

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header/>
                <Switch>
                    <Route exact path='/privacy' component={Policy}/>
                    <Route exact path='/contact' component={Contact}/>
                    <Route exact path='/' component={Top}/>
                </Switch>
                <Footer/>
            </div>
        );
    }
}

export default App;
