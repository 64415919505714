import './Header.scss'
import * as React from "react";

class Header extends React.Component {
    render() {
        return (
            <header className="Header">
                <div className="Header-Image">
                    <img src="/assets/logo.png"/>
                </div>
                <div className="Header-Menu">
                    <a href="/">HOME</a>
                    <a href="/#service">SERVICES</a>
                    <a href="/#about">ABOUT</a>
                    <a href="/contact">CONTACT</a>
                </div>
            </header>
        );
    }
}

export default Header;
