import React, {Component} from 'react';
import './Contact.scss';
import {NavLink} from "react-router-dom";

class Contact extends Component {
    render() {
        return (
            <article className="ContactPage">

                <h3>お問い合わせ</h3>

                <section className="Message">
                    <p>入力前に、弊社の個人情報お取り扱いについて「<NavLink to="/privacy">プライバシーポリシー</NavLink>」をお読み下さい。</p>
                    <p>「送信」ボタンを押すことで、同プライバシーポリシーに同意したものとみなします。</p>
                </section>

                <form>
                    <div className="Field">
                        <label htmlFor="form-name">氏名<span>(必須)</span></label>
                        <input type="text" name="form-name" id="form-name" className="name" required={true}/>
                    </div>

                    <div className="Field">
                        <label htmlFor="form-mail">メールアドレス<span>(必須)</span></label>
                        <input type="mail" name="form-mail" id="form-name" className="name" required={true}/>
                    </div>

                    <div className="Field">
                        <label htmlFor="form-body">問い合わせ内容<span>(必須)</span></label>
                        <textarea name="form-body" id="form-name" className="name"
                               required={true}/>
                    </div>

                    <button type="submit" disabled={true}>送信</button> (準備中)


                </form>

            </article>
        );
    }
}

export default Contact;
