import React, {Component} from 'react';
import './Top.scss';
import Header from "../../core/header/Header";
import Footer from "../../core/footer/Footer";

class Top extends Component {
    render() {
        return (
            <article className="Top">

                <div className="Concept">
                    <div className="Message">
                        <h3>生活のリズムに変化を。</h3>
                        <p>日々の生活の中で繰り返し行われる行動が今より楽しくなるためのサービスを提案します。</p>
                    </div>
                </div>

                <article id="service" className="Business">
                    <section className="Purpose">
                        <img src="/assets/top/business_purpose_1.jpg"/>
                        <h4>事業内容</h4>

                        <h5>サービス開発</h5>
                        <p>Webサービス、スマートフォンアプリケーション、IoTやデータプラットフォームといった、ITを活用した事業およびサービス開発を行います。</p>

                        <h5>受託開発</h5>
                        <p>
                            サービス開発で培った技術を活用し、受託開発を行います。
                            開発のご依頼は問い合わせページよりご連絡ください。
                        </p>

                    </section>
                    <section className="Service">
                        <img src="/assets/top/business_service.jpg"/>
                        <h4>自社サービス</h4>
                        <p>準備中</p>
                    </section>
                </article>

            </article>
        );
    }
}

export default Top;
